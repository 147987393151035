body {
  margin: 0;
}

.container {
  text-align: center;
}

/* vertical and horizontal center */
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
